<template>
  <div>
    <div v-if="isValidUrl">
      <img v-if="isImage" :src="url" alt="image file" class="media-file" />
      <audio v-if="isAudio" controls class="media-file">
        <source :src="url" :type="audioType" />
        Your browser does not support the audio element.
      </audio>
      <video v-if="isVideo" controls class="media-file">
        <source :src="url" type="video/mp4" />
        Your browser does not support the video element.
      </video>
      <embed v-if="isPDF" :src="url" type="application/pdf" width="600" height="500" />
    </div>

    <p v-else>{{ $t('invalid_url') }}</p>
  </div>
</template>

<script>
export default {
  name: 'FileViewerComponent',
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      allowedExtensions: ['jpg', 'jpeg', 'mp3', 'wav', 'pdf', 'mp4', 'svg'],
      isValidUrl: false
    };
  },
  computed: {
    fileExtension() {
      const urlWithoutParams = this.url.split('?')[0];
      return urlWithoutParams.split('.').pop().toLowerCase();
    },
    isImage() {
      return ['jpg', 'jpeg', 'svg'].includes(this.fileExtension);
    },
    isAudio() {
      return ['mp3', 'wav'].includes(this.fileExtension);
    },
    isVideo() {
      return this.fileExtension === 'mp4';
    },
    isPDF() {
      return this.fileExtension === 'pdf';
    },
    audioType() {
      return `audio/${this.fileExtension}`;
    }
  },
  mounted() {
    this.validateUrl();
  },
  methods: {
    validateUrl() {
      try {
        const urlObj = new URL(this.url);
        const isAllowedExtension = this.allowedExtensions.includes(this.fileExtension);
        this.isValidUrl = isAllowedExtension && urlObj.protocol.startsWith('http');
      } catch (error) {
        this.isValidUrl = false;
      }
    },
  }
};
</script>

<style scoped>
.media-file {
  max-width: 250px;
  max-height: 150px;
}
</style>
