<template>
    <div>
      <div class="container-action-buttons">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="sucess" v-bind="attrs" v-on="on" fab class="btn-navegation" x-small @click="$emit('goBack')" elevation="0"><v-icon color="white">arrow_back</v-icon></v-btn>
          </template>
          <span>{{$t('back')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="accent" v-bind="attrs" v-on="on" fab class="ml-3 btn-navegation" x-small @click="$emit('changeAdd')" elevation="0"><v-icon color="white">{{ showAdd ? 'close' : 'add' }}</v-icon></v-btn>
          </template>
          <span>{{showAdd ? $t('close') : $t('add')}}</span>
        </v-tooltip>
        <v-expand-transition>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-show="showAdd" color="info" v-bind="attrs" v-on="on" fab class="ml-3 btn-navegation" x-small @click="openFileInput()" elevation="0"><v-icon color="white">description</v-icon></v-btn>
            </template>
            <span>{{$t('file_upload')}}</span>
          </v-tooltip>
        </v-expand-transition>
        <v-expand-transition>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-show="showAdd" color="warning" v-bind="attrs" v-on="on" fab class="ml-3 btn-navegation" x-small @click="dialogCreateFolder = true;" elevation="0"><v-icon color="white">folder</v-icon></v-btn>
            </template>
            <span>{{$t('folder_create')}}</span>
          </v-tooltip>
        </v-expand-transition>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="info" v-bind="attrs" v-on="on" fab class="ml-3 btn-navegation" x-small @click="$emit('refreshFiles')" elevation="0"><v-icon color="white">refresh</v-icon></v-btn>
          </template>
          <span>{{$t('refresh')}}</span>
        </v-tooltip>
      </div>
      <v-dialog v-model="dialogCreateFolder" max-width="400" persistent>
        <v-card class="scroll">
          <v-card-title><p class="text-dialog-confirm mb-0">{{ $t('folder_new') }}</p></v-card-title>
          <v-card-text class="mb-1">
            <div class="ma-2 mb-4">
                <v-text-field outlined dense :label="$t('folder_no_title')" v-model="nameFolder"></v-text-field>
            </div>
            <v-row justify="end">
              <div>
                <v-btn color="#C00AC0" @click="createFolder()" class="mr-3 white--text">{{$t('accept')}}</v-btn>
                <v-btn outlined color="#061A40" @click="dialogCreateFolder = false;">{{$t('cancel')}}</v-btn>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <input type="file" ref="fileIn" style="display: none" accept="image/jpg,image/jpeg,audio/mp3,audio/wav,audio/gsm,.pdf,.mp4,.svg" @change="onFileChangeIn">
    </div>
</template>
<script>

import sharedMethods from '../../mixins/sharedMethods.js'
export default {
  name: 'ActionButtonsComponent',
  mixins: [sharedMethods],
  props:{
    path: {type: String, default: ''},
    showAdd: {type: Boolean, default: false},
  },
  data(){
    return {
      fab: false,
      dialogCreateFolder: false,
      nameFolder: '',
    }
  },
  watch: {
  },
  computed: {

  },
  created() {

  },mounted(){

  },methods: {
    openFileInput() {
      this.$refs.fileIn.value = null;
      this.$refs.fileIn.click();
    },
    onFileChangeIn(e){
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length){
        return;
      }
      this.addFile(files[0])
    },
    async addFile(file){
      if(!file){
        this.$emit('showSnackbarError', this.$t('file_upload_please'))
        return
      }
      let formData = new FormData()
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("action", 'uploadFile');
      formData.append("file", file);
      formData.append("path", this.path);
      let headers = {};
      if (process.env.NODE_ENV === 'production') {
        headers = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
          },
          withCredentials: true
        }
      } else {
        //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }
        }
      }

      this.loadingFiles = true;

      let url = this.baseUrl + 'server/controller.php';
      await this.axios.post(url, formData, headers).then(async (result) => {
          this.loadingFiles = false;
          if(result.data.code == '200'){
            this.$emit('showSnackbarError', this.$t('file_upload_successfully'))
            this.$emit('changeAdd');
          }else{
            this.$emit('showSnackbarError', this.$t('been_problem_upload_file'))
          }
          this.$emit('refreshFiles');
      })
      .catch((error) => {
        console.error(error)
        this.loadingFiles = false;
        this.$emit('showSnackbarError', this.$t('been_problem_upload_file'))
      })
    },
    async createFolder(){
        this.nameFolder = this.nameFolder.trim();
        this.nameFolder = this.nameFolder.replace(/[\s]/g, '_');
        if (this.nameFolder == '') {
            this.$emit('showSnackbarError', this.$t('file_name_not_empty'))
            return;
        }

        this.dialogCreateFolder = false;

        let formData = new FormData()
        formData.append("username", this.$store.getters.globalInfo.username);
        formData.append("operation", this.$store.getters.globalInfo.operation);
        formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
        formData.append("action", 'createFolder');
        formData.append("folder_name", this.nameFolder);
        formData.append("path", this.path);
        let headers = {};
        if (process.env.NODE_ENV === 'production') {
          headers = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
            },
            withCredentials: true
          }
        } else {
          //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }
        }
        }

        let url = this.baseUrl + 'server/controller.php';
        await this.axios.post(url, formData, headers).then(async (result) => {
            if(result.data.code == '200'){
                this.$emit('showSnackbarError', this.$t('folder_create_successfully'))
                this.$emit('changeAdd');
            }else{
                this.$emit('showSnackbarError', this.$t('been_problem_create_file'))
            }
            this.$emit('refreshFiles');
        })
        .catch((error) => {
            console.error(error)
            this.loadingFiles = false;
            this.$emit('showSnackbarError', this.$t('been_problem_create_file'))
        })
        this.nameFolder = '';
    }
  }
}
</script>
<style scoped>

.container-action-buttons {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
}
</style>
