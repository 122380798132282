<template>
    <div>
      <v-card class="pa-3" tile elevation-0>
        <v-overlay color="#FFFFFF" :value="loadingFiles" absolute opacity="1">
          <v-progress-circular color="#000000" indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col cols="12" lg="7" md="7" sm="7" xs="12" class="pr-0 pl-0" :style="!$vuetify.breakpoint.xs ? 'order: 1;': 'order: 2;'">
              <div class="container scroll">
                <v-row>
                  <v-col v-for="(item, i) in items" :key="i" cols="12" lg="4" md="6" sm="12" xs="12">
                    <v-card class="pa-2 card-item" elevation>
                      <div class="ml-4">
                        <v-icon class="mt-4 mb-2 text-left" large :color="item.color">{{item.icon}}</v-icon>
                        <p class="item-title mt-4 mb-5">{{item.text}}</p>
                        <div class="d-flex">
                          <p class="item-description mt-7 mb-0">{{item.size}} GB</p>
                          <p class="item-description mt-7 mb-0 mx-5">-</p>
                          <p class="item-description mt-7 mb-0">{{item.items}} items</p>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="5" xs="12" class="pt-6" :style="!$vuetify.breakpoint.xs ? 'order: 2;': 'order: 1;'">
              <v-card :class="!$vuetify.breakpoint.xs ? 'container-chart scroll' : 'container-chart-mobile scroll'">
                <div ref="chartdiv" style="width: 100%; height: 500px;"></div>
              </v-card>
            </v-col>
          </v-row>
      </v-card>
        <v-snackbar color="#061A40" v-model="snackbarError" timeout="2000">
          {{snackbarErrorMessage}}
          <template v-slot:action="{ attrs }">
            <v-btn color="#C00AC0" text v-bind="attrs" @click="snackbarError = false">{{$t('accept')}}</v-btn>
          </template>
        </v-snackbar>
    </div>
</template>
<script>

import sharedMethods from '../../mixins/sharedMethods.js'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
export default {
  name: 'DashboardComponent',
  mixins: [sharedMethods],
  data(){
    return{
      totalStorage: '16',
      itemsValue: [
        {name: this.$t('documents'), value: 'documents', icon: 'description'},
        {name: this.$t('images'), value: 'images', icon: 'image'},
        {name: this.$t('videos'), value: 'videos', icon: 'videocam'},
        {name: this.$t('audios'), value: 'audios', icon: 'music_note'},
        {name: this.$t('others'), value: 'others', icon: 'dynamic_feed'},
      ],
      items: [],
      colors:[
        "#845EC2",
        "#D65DB1",
        "#FF6F91",
        "#FF9671",
        "#FFC75F",
        "#F9F871"
      ],
      snackbarError: false,
      snackbarErrorMessage: '',
      chart: null,
      loadingFiles: false,
    }
  },
  computed: {

  },
  created(){

    this.getInfoFiles();
  },mounted(){

  },methods: {
    async getInfoFiles(){

      let formData = new FormData()
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("action", 'getInfoBucket');

      let headers = {};
      if (process.env.NODE_ENV === 'production') {
        headers = {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          },
          withCredentials: true
        }
      } else {
        //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }, withCredentials: false
        }
      }

      this.loadingFiles = true;
      let url = this.baseUrl + 'server/controller.php';
      await this.axios.post(url, formData, headers).then(async (result) => {
          if(result.data.code == '200' && result.data.msg.length > 0){
            this.items = result.data.msg
            this.items = await this.mergeArrays(this.items, this.itemsValue)
            await this.organizeObjects()
          }
          this.loadingFiles = false
          this.mountedChart();
      })
      .catch((error) => {
        console.error(error)
        this.loadingFiles = false
        this.snackbarErrorMessage = this.$t('been_problem')
        this.snackbarError = true
      })
    },
    mountedChart(){

      this.chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);

      this.chart.data = this.items;

      var pieSeries = this.chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "size";
      pieSeries.dataFields.category = "text";
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;
      pieSeries.slices.template.adapter.add("fill", function (fill, target) {
        var color = target.dataItem.dataContext.color;
        return color;
      });

      this.chart.legend = new am4charts.Legend();
      this.chart.legend.position = "bottom";
      this.chart.legend.orientation = "horizontal";
      this.chart.legend.fontSize = "13px";
      this.chart.legend.paddingBottom = "20px";
      this.chart.legend.labels.template.text = "{text}";
      let markerTemplate = this.chart.legend.markers.template;
      markerTemplate.width = 17;
      markerTemplate.height = 17;

      this.chart.innerRadius = am4core.percent(60);

      var label = pieSeries.createChild(am4core.Label);
      label.text = "{values.value.sum} Gb";
      label.horizontalCenter = "middle";
      label.verticalCenter = "middle";
      label.fontSize = 40;
    },
    mergeArrays(arr1, arr2) {
      return arr1.map(item1 => {
      let matchingItem = arr2.find(item2 => item1.item === item2.value);
        if (matchingItem) {
          return { ...item1, text: matchingItem.name, icon: matchingItem.icon };
        }
        return item1;
      });
    },
    organizeObjects(){
      this.items.sort((a, b) => b.size - a.size);
      this.items.forEach((item, index) => {
        let colorIndex = index % this.colors.length;
        item.color = this.colors[colorIndex];
      });
    }
  }
}
</script>
<style scoped>
.container{
  height: calc(100vh - 100px);
}
.container-chart{
  height: calc(100vh - 100px);
}
.container-chart-mobile{
  height: calc(69vh);
}
.scroll{
  overflow: auto!important;
}
.scroll::-webkit-scrollbar{
  -webkit-appearance: none;
}
.scroll::-webkit-scrollbar:vertical{
  width:10px;
}
.scroll::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button{
  display: none;
}
.scroll::-webkit-scrollbar:horizontal{
  height: 10px;
}
.scroll::-webkit-scrollbar-thumb{
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}
.scroll::-webkit-scrollbar-track{
    border-radius: 10px;
}
.card-item{
  height: 250px;
}
.item-title{
  font-size: 20px;
  font-weight: 700;
  color: #1C1133;
}
.item-description{
  font-size: 16px;
  font-weight: 600;
  color: #1C1133;
}
</style>
