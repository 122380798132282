<template>
    <div>
      <div class="ml-3 mr-3 mt-3">
        <v-card class="scroll elevation-1" outlined>
          <v-overlay color="#FFFFFF" :value="loadingLog" absolute opacity="1">
            <v-progress-circular color="#000000" indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-toolbar color="#FFFFFF" elevation="1" dense height="50" class="app-bar">
            <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field style="max-width: 300px" v-model="date" :label="$t('date_ini')" hide-details readonly v-bind="attrs" v-on="on" dense outlined class="mr-3" append-icon="calendar_month"></v-text-field>
                </template>
                <v-date-picker color="secondary" :max="maxDate" :max-date="maxDate" v-model="date" no-title @input="menu2 = false" ></v-date-picker>
            </v-menu>
            <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field style="max-width: 300px" v-model="date2" :label="$t('date_end')" hide-details readonly v-bind="attrs" v-on="on" dense outlined class="mr-3" append-icon="calendar_month"></v-text-field>
                </template>
                <v-date-picker color="secondary" :max="maxDate" :max-date="maxDate" v-model="date2" no-title @input="menu2 = false" ></v-date-picker>
            </v-menu>
            <v-btn color="#1C1133" class="white--text" @click="getLog()">{{$t('search')}}</v-btn>
            <v-spacer></v-spacer>
            <v-text-field style="max-width: 300px" v-model="search" :label="$t('search')" hide-details dense outlined class="mr-3" append-icon="search"></v-text-field>
          </v-toolbar>
          <v-data-table dense :headers="headersTableLog" :items="bodyTableLog" class="scroll table-files pl-0 elevation-0 rounded-0 pt-0" hide-default-footer :items-per-page="-1" :mobile-breakpoint="0" :search="search" fixed-header locale="es">
          </v-data-table>
        </v-card>
      </div>

      <v-snackbar color="#C00AC0" v-model="snackbarError" timeout="4000">
      {{snackbarErrorMessage}}
        <template v-slot:action="{ attrs }">
          <v-btn color="#1C1133" text v-bind="attrs" @click="snackbarError = false">{{$t('accept')}}</v-btn>
        </template>
      </v-snackbar>
    </div>
</template>
<script>

import sharedMethods from '../../mixins/sharedMethods.js'
export default {
  name: 'AuditLogComponent',
  mixins: [sharedMethods],
  components:{
  },
  data(){
    return {
      snackbarError: false,
      snackbarErrorMessage: '',
      loadingLog: false,
      headersTableLog: [{text: this.$t('date'), value: 'date'}, {text: 'IP', value: 'ip'}, {text: this.$t('user'), value: 'user'}, {text: this.$t('action'), value: 'action'}],
      bodyTableLog: [],
      search: '',
      menu1: false,
      menu2: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      dateFormatted2: this.formatDate2((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      maxDate: new Date().toISOString().substr(0, 10),
    }
  },
  watch: {
    date(val) {
        this.dateFormatted = this.formatDate(this.date)
    },
    date2(val) {
        this.dateFormatted2 = this.formatDate2(this.date2)
    },
  },
  computed: {
  },
  created() {

  },mounted(){
    this.getLog()
  },methods: {
    getLog(){

        this.bodyTableLog = [];
        let formData = new FormData()
        formData.append("username", this.$store.getters.globalInfo.username);
        formData.append("operation", this.$store.getters.globalInfo.operation);
        formData.append("date_ini", this.dateFormatted);
        formData.append("date_end", this.dateFormatted2);
        formData.append("action", 'auditLog');

        let headers = {};
        if (process.env.NODE_ENV === 'production') {
          headers = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
            },
            withCredentials: true
          }
        } else {
          headers = {
            headers:{
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
              "Cache-Control": "no-cache",
            }
          }
        }

        this.loadingLog = true;
        let url = this.baseUrl + 'server/controller.php';
        this.axios.post(url, formData, headers).then(async (result) => {
            if(result.data.code == '200'){
            let information = result.data.msg
            if(information == null){
                this.loadingFiles = false;
                return
            }
            information.forEach(element => {
                this.bodyTableLog.push({name: element.action, date: element.date, ip: element.ip, user: element.user, action: element.action})
            });
            }
            this.loadingLog = false;
        })
        .catch((error) => {
        console.error(error)
        this.loadingLog = false;
        this.snackbarErrorMessage = this.$t('been_problem')
        this.snackbarError = true
        })
    },
    formatDate(date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${year}${month}${day}000000`
    },
    formatDate2(date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${year}${month}${day}235959`
    },
  }
}
</script>
<style scoped>
.text-files{
	color: #061A40;
  font-size: 23px;
	font-weight: 700;
}
.v-data-table >>> th {
  text-transform: uppercase;
  background-color: #F5F5F5!important;
}
.table-files{
	height: calc(100vh - 134px);
}
.table-files-mobile{
	height: calc(100vh - 260px);
}
.scroll{
  overflow: auto!important;
}
.scroll::-webkit-scrollbar{
  -webkit-appearance: none;
}
.scroll::-webkit-scrollbar:vertical{
  width:10px;
}
.scroll::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button{
  display: none;
}
.scroll::-webkit-scrollbar:horizontal{
  height: 10px;
}
.scroll::-webkit-scrollbar-thumb{
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}
.scroll::-webkit-scrollbar-track{
    border-radius: 10px;
}
.mytable table thead tr th{
  background: #F5F5F5!important;
}
.mytable table tbody td{
  height: 32px!important;
}
.tdHeadTable{
  font-family: "Montserrat", sans-serif;
  color: #000000;
}
.aSearch{
  color: #000000;
  font-size: 13px;;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.btn-see{
  margin-top: 1px;
}
.text-menu-mobile{
	color: #061A40;
	font-weight: 700;
}
.btn-navegation{
  color: #E0E0E0!important;
}
.btn-navegation >>> .v-btn__content{
  color: #000000;
}
.text-dialog-confirm{
	white-space:pre-wrap;
  word-break:break-word;
}
.folder{
  color: #1C1133;
  font-weight: 500;
}
.folder:hover{
  color: #1C1133;
  cursor: pointer;
  transition: color 0.3s;
  font-weight: 800;
}
</style>
