import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from 'vuetify/lib/locale/en';
import es from 'vuetify/lib/locale/es';
import pt from 'vuetify/lib/locale/pt';
import i18n from '../i18n';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    lang: {
        locales: { en, es, pt },
        current: getLanguage(),
    },
    theme: {
        themes: {
            light: {
                primary: '#C00AC0',
                secondary: '#1C1133',
                accent: '#640964',
                info: '#607d8b',
                sucess: '#32408f',
                warning: '#9367d9',
            },
        },
    },
});

function getLanguage() {
    const ln = window.navigator.userLanguage || window.navigator.language;
    const langtag = ln.split("-");
    let language = langtag[0];

    if (language !== "es" && language !== "en" && language !== "pt") {
      language = 'en';
    }

    return language;
  }
