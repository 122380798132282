<template>
    <div>
      <v-main>
        <v-row no-gutters align="center" justify="center">
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-img v-if="!$vuetify.breakpoint.xs" class="img-background" src="../assets/wolkvox_storage.png"></v-img>
            <img alt="ConfigurationManager" v-cloak v-if="$vuetify.breakpoint.xs" class="img-background-mobile" src="../assets/wolkvox_mobile.png" max-width="40%">
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <login/>
          </v-col>
          <p v-if="!$vuetify.breakpoint.xs" class="text-copyright">WOLKVOX<span>&#8482;</span> {{yearCopyright}}. ALL RIGHTS RESERVED</p>
        </v-row>
      </v-main>
    </div>
  </template>
  
  <script>
    import Login from '../components/Login/Login.vue'
  
    export default {
      name: 'login-view',
      components: {
        Login,
      },
      data: () => ({
        yearCopyright: "",
      }),
      created(){
        this.getYear();
      },
      methods: {
        getYear(){
          let year = new Date();
          this.yearCopyright = year.getFullYear();
        },
      }
    }
  </script>
  
  <style scoped>
  .img-background{
    height: 100vh!important;
  }
  .img-background-mobile{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto!important;
    margin-top: 9vh;
    margin-bottom: 9vh;
  }
  .text-copyright{
    margin-left: 50%;
    margin-top: -40px;
    font-weight: 400;
    font-size: 13px;
  }
  .text-copyright span{
    margin-left: 3px;
  }
  [v-cloak] {
    display: none;
  }
  </style>
  