import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import i18n from './i18n';

Vue.config.productionTip = false

Vue.prototype.axios = axios

// Definir URL basada en el entorno
const baseUrl = process.env.NODE_ENV === 'production' ? 'https://storage.wolkvox.com/' : 'https://localhost/';
Vue.prototype.baseUrl = baseUrl;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  i18n,
}).$mount('#app')
