<template>
  <v-app>
    <NavegationMenu @menu="changeMenu" v-if="$store.state.auth"/>
    <v-main class="light">
      <router-view :reportMenuPass="itemMenu" :menuSelectMobile="menu"/>
    </v-main>
  </v-app>
</template>

<script>
import NavegationMenu from './components/Layout/NavegationMenu.vue';
import sharedMethods from '../src/mixins/sharedMethods.js';
export default {
  name: 'App',
  components: {
    NavegationMenu
  },
  mixins: [sharedMethods],
  data(){
    return {
      itemMenu: 0,
      menu: '',
    }
  },
  computed:{
  },
  mounted(){
    this.dragDialog()
  },
  methods: {
    dragDialog(){
      (function () { // make vuetify dialogs movable
        const d = {};
        document.addEventListener("mousedown", e => {
          const closestDialog = e.target.closest(".v-dialog.dialogDrag.v-dialog--active");
          if (e.button === 0 && closestDialog != null && e.target.classList.contains("v-card__title") || e.target.classList.contains("titleREc")) { // element which can be used to move element
              d.el = closestDialog; // element which should be moved
              d.mouseStartX = e.clientX;
              d.mouseStartY = e.clientY;
              d.elStartX = d.el.getBoundingClientRect().left;
              d.elStartY = d.el.getBoundingClientRect().top;
              d.el.style.position = "fixed";
              d.el.style.margin = 0;
              d.oldTransition = d.el.style.transition;
              d.el.style.transition = "none"
          }
        });
        document.addEventListener("mousemove", e => {
            if (d.el === undefined) return;
            d.el.style.left = Math.min(
              Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
              window.innerWidth - d.el.getBoundingClientRect().width
            ) + "px";
            d.el.style.top = Math.min(
              Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
              window.innerHeight - d.el.getBoundingClientRect().height
            ) + "px";
        });
        document.addEventListener("mouseup", () => {
          if (d.el === undefined) return;
          d.el.style.transition = d.oldTransition;
          d.el = undefined
        });
        setInterval(() => { // prevent out of bounds
          const dialog = document.querySelector(".v-dialog.v-dialog--active");
          if (dialog === null) return;
          dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
          dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
        }, 100);
      })();
    },
    changeMenu(menu, item){
      this.itemMenu = item
      this.menu = menu
    }
  }
};
</script>

<style scoped>
.app-bar >>> .v-toolbar__content {
  padding-left: 0px!important;
}
.app-bar.elevation-1{
  box-shadow: 0px 0.3px 0.3px 0.3px #eeeeee !important;
}
#navigation{
  width: 56px;
  height: 60px;
  border-radius: 0px;
  background-color: #2763AE;
  color: white;
}
.background-logo{
  background-color: #2763AE;
  min-width: 174px;
  height: 60px;
  margin: 0px;
}
.avatar {
  margin-left: 10px;
  margin-right: 10px;
}
.text-operation{
  font-weight: 700;
  line-height: 15px;
}
.text-user{
  font-weight: 500;
}

.link-active,
.link-after,
.link:before{
  border-radius: 0px 2em 2em 0px !important;
  background: #3C7FFA;
  padding-right: 10px!important;
  color: #eeeeee;
}
.link:hover{
  background: #3C7FFA;
  opacity: 0.5;
}
.link2:after,
.link2:before{
  background: #3C7FFA;
  color: #eeeeee;
}
.link2:hover{
  background: #3C7FFA;
  opacity: 0.5;
}

.link-active2{
  background: #3C7FFA;
}
.light::-webkit-scrollbar {
  width: 15px;
}

.light::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

.light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

.light::-webkit-scrollbar-thumb:hover {
  background: black;
}

.dark::-webkit-scrollbar {
  width: 15px;
}

.dark::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.dark::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.dark::-webkit-scrollbar-thumb:hover {
  background: white;
}
.divider {
  margin: 0px 5px 0px 20px;
}
.v-divider--inset{
  height: 35%;
  margin-top: 20px!important;
}

</style>